import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from "moment"
import { Fade } from "react-awesome-reveal";
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { StaticImage } from "gatsby-plugin-image"

var yearsSinceOpening =  moment().diff('2012-01-07', 'years');

const IndexPage = ({ intl }) => {
  return (<Layout>
    <SEO title="Home" />
    <div className="hero-image">
      <div className="hero-image-text">
        <p className="hero-image-title hero-image-title--large">{intl.formatMessage({ id: "home.hero.title" })}</p>
        <div className="home-image-subtitle">
          <p className="home-image-subtitle__text">{intl.formatMessage({ id: "home.hero.subtitle1" })}</p>
          <p className="home-image-subtitle__text">{intl.formatMessage({ id: "home.hero.subtitle2" })}</p>
          <p className="home-image-subtitle__text">{intl.formatMessage({ id: "home.hero.subtitle3" })}</p>
        </div>
      </div>
      <StaticImage className="hero-image__image hero-image__image--large" src="../images/shop/counter2.jpg" alt={intl.formatMessage({ id: "home.hero.image" })}/>
    </div>
    <div className="flex-center">
      <Fade triggerOnce={true}>
        <div className="text-container">
          <h4 className="title">{intl.formatMessage({ id: "home.title1" })}</h4>
          <p className="text">{intl.formatMessage({ id: "home.text1" })}</p>
        </div>
      </Fade>
    </div>
    <div className="home-image-container">
      <div className="hero-image hero-image--small">
        <div className="hero-image-text">
          <Fade triggerOnce={true} left>
            <p className="hero-image-title">{intl.formatMessage({ id: "home.hero2.title" })}</p>
          </Fade>
          <Fade triggerOnce={true} right>
            <Link to="/menu/" className="button">{intl.formatMessage({ id: "home.hero2.button" })}</Link>
          </Fade>
        </div>
        <StaticImage className="hero-image__image" src="../images/shop/counter.jpg" alt={intl.formatMessage({ id: "home.hero2.image" })}/>
      </div>
      <div className="hero-image hero-image--small">
        <div className="hero-image-text">
          <Fade triggerOnce={true} right>
            <p className="hero-image-title">{intl.formatMessage({ id: "home.hero3.title" })}</p>
          </Fade>
          <Fade triggerOnce={true} left>
            <Link to="/contact/" className="button">{intl.formatMessage({ id: "home.hero3.button" })}</Link>
          </Fade>
        </div>
        <StaticImage className="hero-image__image" src="../images/shop/outsidefull.jpg" alt={intl.formatMessage({ id: "home.hero3.image" })}/>
      </div>
    </div>
    <div className="flex-center">
      <Fade triggerOnce={true}>
        <div className="text-container">
          <div className="flex-center">
            <div>
              <h4 className="big-title">{intl.formatMessage({ id: "home.big-title-left" })}</h4>
              <h4 className="big-title big-title--right">{intl.formatMessage({ id: "home.big-title-right" })}</h4>
            </div>
          </div>
          <p className="text text--spaced">{intl.formatMessage({ id: "home.open-text-left" })} {yearsSinceOpening} {intl.formatMessage({ id: "home.open-text-right" })}</p>
          <h4 className="title title--condensed">{intl.formatMessage({ id: "opening-hours.title" })}</h4>
          <p className="text text--centered">
            {intl.formatMessage({ id: "opening-hours.days" })}<br/>
            {intl.formatMessage({ id: "opening-hours.hours" })}
          </p>
        </div>
      </Fade>
    </div>
    <iframe title="map" className="home-map" src={intl.formatMessage({ id: "map" })} width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
  </Layout>
  )
}

export default injectIntl(IndexPage)
